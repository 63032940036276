@import '../../../shared/shared.scss';


.AppRoute{
    width: 100%;
    height: 100%;

    .AppClipper{
        position: absolute;
        clip: rect(5px, 1920px, 1080px, 0px);
        width: 1920px;
        height: 1080px;
    }
    
    transition: opacity 0.5s;
    transition-delay: 0.35s;

    &[data-init="false"]{
        opacity: 1;
    }

    &[data-init="true"]{
        opacity: 0;
    }
}