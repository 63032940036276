@import "../../../shared/shared.scss";

.menu {
    padding-top: 70px;
    list-style-type: none;
}

.side-menu-main {
    margin-bottom: 40px;
    height: 350px;
}

.menu-group {
    width: 10%;
    height: 390px;
    margin-top: 735px;
    position: absolute;
    right: 70px;
    border-radius: 20px;
    padding-top: 5px;

    display: flex;
    flex-direction: column; /* Ensures items stack vertically */
    gap: 10px; /* Adjust spacing between items */
    align-items: left; /* Center the items horizontally */
}

.menu-group > .menu-stacker2 {
    margin-left: -20px;
}

.menu-items {
    width: 10%;
    height: 50px;
    margin-top: 380px;
    position: absolute;
    right: 50px;

}

.menu-group-item {
    margin-top: 115px;
    list-style-type: none;
    font-size: 24px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-style-floor;

    color: $rollover;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

/*%shared-menu-item-styles {
    background-color: $highlightTextColour;
    width: 130px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-style-floor;
    color: $rollover;
    cursor: pointer;

    &:hover {
        color: rgb(94, 21, 21);       
    }
}

.menu-item,
.menu-item-selected {
    @extend %shared-menu-item-styles

}*/
.menu-item {
    background-color: #899B93;
    width: 140px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #C8D1CE; 
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 2px;
    @include font-style-floor;
    cursor: pointer;
    color: #fbe8d4;

    &:hover {
        color: white;
        border: 1px solid white;       
    }
}

.menu-item-selected {
    color: #fbe8d4;
    background-color: #899b93;
    width: 140px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 2px;
    @include font-style-floor;
    color: $rollover;
    cursor: pointer;

    &:hover {
        color: white;     
    }
}

// .menu-dusk-sunset {
//     background-color: #899b93;
//     width: 140px;
//     height: 40px;
//     border-radius: 20px;
//     border: 1px solid white;
//     display: flex;
//     align-items: center;
//     margin-top: -250px;
//     list-style-type: none;
//     font-size: 18px;
//     text-align: center;
//     display: flex;
//     justify-content: center;
//     margin-left: -16px;
//     @include font-style-floor;
//     color: $rollover;
// }

.menu-stacker {
    flex-direction: column;
    background-color: #899B93;
    
    width: 160px;
    height: 95px;
    border-radius: 20px;
    border: 2px solid #C8D1CE;
    display: flex;
    align-items: center;
    margin-top: 20px;
    list-style-type: none;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-style-floor;
    color: $rollover;
}

.menu-stacker2 {
    flex-direction: column;
    background-color: #899B93;
    margin-left: -50px;
    width: 200px;
    height: 165px;
    border-radius: 20px;
    border: 2px solid #C8D1CE;
    display: flex;
    align-items: center;
    margin-top: 20px;
    list-style-type: none;
    font-size: 17px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-directions;
    color: $rollover;
}

.menu-item-stacker {
    width: 160px;

    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
        color: white;
        text-decoration: underline;
        text-underline-offset: 5px; 

    }
}

.menu-dusk-sunset-single {
    background-color: $highlightTextColour;
    width: 134px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-style-floor;
    color: $rollover;
}

.menu-item-stacker-selected {
    text-decoration: underline;

    margin-top: 5px;
    margin-bottom: 5px;
    text-underline-offset: 5px; 
    cursor: pointer;
    color: white;
}