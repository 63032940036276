@import '../../../shared/shared.scss';

.LevelSelectorItemDayNight {
    display: flex;
    width: 260px;
    height: 50px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    border: 1px solid lightgrey;
    padding: 5px;
    background-color: white;
}

.LevelSelectorLabelDayNight{
    border-radius: 10px;
    width: 120px;
    height: 40px;
    border: 1px solid lightgrey;;
    opacity: 1;
    letter-spacing: 0.05em;
    font-size: 16px;

    @include font-style-floor;
}

.daynightselector {
    position: absolute;
    margin-left: 260px;
    margin-top: -820px;
    display: flex;
    flex-direction: column;
}

.daynightlabel {
    font-size: 16px;
    position: absolute;
    margin-top: -80px;
    @include font-style-floor;
    color: #7a614b;
    justify-content: center;
    pointer-events: none;
}

.daynightradio {
    margin-bottom: 55px;
    width: 40px;
    height: 40px;
    z-index: 100;
}

.daynightselector {
    display: flex;
    flex-direction: column; /* Arrange vertically */
    justify-content: flex-start;
    align-items: flex-start;
  
    .custom-radio {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      margin-bottom: 53px; /* Vertical spacing between radio buttons */
  
      input[type="radio"] {
        position: absolute;
        opacity: 0; /* Hide the native radio button */
        cursor: pointer;
      }
  
      .checkmark {
        height: 44px; /* Custom size */
        width: 44px;  /* Custom size */
        background-color: #fbe8d4;
        border-radius: 50%;
        border: 2px solid #7a614b;
        margin-right: 20px; /* Space between radio button and label */
        position: relative;
      }
  
      input[type="radio"]:checked + .checkmark {
        background-color: #fbe8d4; /* Custom color for checked state */
        border-color: #fbe8d4;
        border: 2px solid #7a614b;;
      }
  
      input[type="radio"]:checked + .checkmark::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 27px;
        width: 27px;
        background-color: #7a614b;
        border-radius: 50%;
      }
    }
  
    .daynightlabel {
      font-size: 16px;
      margin-left: 10px; /* Space between the checkmark and label */
    }
  }
// .daynightradio:checked {
//     color: black
// }

// .daynightradio input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//   }

// .radio-dot {
//     height: 20px;
//     width: 20px;
//     border-radius: 50%;
//     background-color: white;
//     border: 2px solid #ccc;
//     display: inline-block;
//     margin-right: 10px;
//     position: relative;
//   }

//   .daynightradio input[type="radio"]:checked + .radio-dot {
//     border-color: #4CAF50;  /* Outer circle color */
//   }

//   .daynightradio input[type="radio"]:checked + .radio-dot::before {
//     content: "";
//     position: absolute;
//     top: 4px;
//     left: 4px;
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     background-color: #4CAF50; /* Dot color */
//   }