	
@import "../../../shared/shared.scss";

.slider--container {
	width: 100%;
	height: 100%;
}

.slider--transform-container {
	height: calc(100% - 100px);
    position: absolute;
	top: 100px;
	background: #e8e3dc;
}

.slick-slider {
	width: 100%;
	height: 100%;

	.slick-track, .slick-list {
		height: 100%;
	}
}

.slick-slide {
	text-align: center;
	position: relative;

	> div {
		height: 100%;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		.slide-captions {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
	
			@include font-secondary;
			font-size: 20px;
		}
	}
}

.slick-arrow {
	width: 50px;
	height: 50px;
	opacity: 1;
	color: #101b37;
	transition: all 0.3s ease-out;
	z-index: 1;

	&:focus:not(.slick-disabled) {
		color: inherit;
		opacity: 1;
	}

	&:hover:not(.slick-disabled) {
		color: #112952;
	}

	&:before {
		content: '';
		position: absolute;
		top: 10px; left: 10px;
		width: 30px; height: 30px;
		opacity: 1;
		color: #101b37;
		border: solid #7a614b;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 2px;
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	&.slick-prev {
		left: 25px;
	}
	&.slick-next {
		right: 25px;
		
		&:before {
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
	}
	&.slick-disabled::before {
		opacity: 0;
	}
}

.slick-dots {
	bottom: 25px;
	z-index: 1;

	&__low {
		bottom: 30px;
	}

	li {
		width: 10px;
		height: 10px;
		margin: 0 4px;

		button {
			color: #7a614b;
			width: 10px;
			height: 10px;

			&:before {
				content: '';
				width: 10px;
				height: 10px;
				color: #7a614b;
				opacity: 1;
				border: 1px solid currentColor;
				border-radius: 50%;
			}
		}
	}
	
	li.slick-active button:before {
		color: #7a614b;
		opacity: 1;
		background-color: currentColor;
	}
}